<template>
  <div class="container">
    <div class="board">
      <draggable
        v-model="project.data.lists"
        draggable=".dragg-item"
        drag-class="dragging"
        ghost-class="ghost"
        class="list-draggable"
        @end="test"
      >
        <div
          :class="{
            'list-top': true,
            'list-wrapper': true,
            'dragg-item': list.fixed !== true,
          }"
          :id="list.id"
          v-for="list in project.data.lists"
          v-bind:key="list.id"
        >
          <div
            class="list"
            :style="{
              'border-top-color': getColorByType(list.type),
            }"
          >
            <div class="list-header" v-on:update="listUpdate">
              <!--
              <textarea
                class="list-header-name mod-list-name js-list-name-input"
                spellcheck="false"
                dir="auto"
                maxlength="512"
                @input="changeTitle(list)"
                v-model="list.title"
                disabled
              /> -->
              <div class="title">
                {{ $t("columns." + list.type).title }}
              </div>

              <v-btn icon small style="float: right">
                <v-icon small>mdi-menu</v-icon>
              </v-btn>
              <v-tooltip
                bottom
                v-if="list.cards.length > 0"
                max-width="272"
                color="blue"
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    style="float: right"
                  >
                    <v-icon small>mdi-help</v-icon>
                  </v-btn>
                </template>
                <div>
                  <ul>
                    <li
                      v-for="(tip, i) in $t('columns.' + list.type).tooltips"
                      :key="i"
                    >
                      {{ tip }}
                    </li>
                  </ul>
                </div>
              </v-tooltip>
            </div>
            <div class="list-cards">
              <draggable
                v-model="list.cards"
                draggable=".dragg-item"
                ghost-class="ghost"
                class="list-draggable"
                :move="check"
                :id="list.id"
                group="cards"
                v-on:update="listUpdate"
                @end="test"
              >
                <card
                  :ref="id"
                  :id="id"
                  :project="project"
                  :type="list.type"
                  v-on:click.native="editCard({ itemId: id, listId: list.id })"
                  v-for="id in list.cards"
                  v-bind:key="id"
                  v-on:update="listUpdate"
                />
                <div slot="header">
                  <v-fade-transition>
                    <div
                      transition="scroll-x-transition"
                      v-if="list.cards.length === 0"
                      :class="{
                        'tooltips-card': true,
                      }"
                    >
                      <ul>
                        <li
                          v-for="(tip, i) in $t('columns.' + list.type)
                            .tooltips"
                          :key="i"
                        >
                          {{ tip }}
                        </li>
                      </ul>
                    </div>
                  </v-fade-transition>
                </div>
              </draggable>
            </div>
            <div class="card-composer-container" v-if="list.fixed !== true">
              <!--
              <input
                class="input-card"
                placeholder="Lag et nytt element"
                v-model="list.newItemName"
                @keyup.enter="createNewItem(list)"
                @blur="createNewItem(list)"
              />
              -->
              <v-text-field
                :placeholder="getNewCardButtonNameByType(list.type)"
                v-model="list.newItemName"
                @keyup.enter="createNewItem(list)"
                @blur="createNewItem(list)"
                :color="getColorByType(list.type)"
                prepend-icon="mdi-plus-thick"
                class="ml-1 mr-1"
                dense
                single-line
                hide-details
              />
            </div>
          </div>
        </div>
      </draggable>
      <!-- <card-editor :itemId="itemId" :listId="listId" /> -->
      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";
import Card from "./Card.vue";
import { mapGetters } from "vuex";
// import CardEditor from './CardEditor.vue';

export default {
  name: "CardLists",
  props: {
    project: Object,
  },
  components: {
    draggable,
    Card,
    // CardEditor,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["DESIGN_SCHEME"]),
  },
  data: () => ({
    projects: {},
    itemId: "",
    listId: "",
  }),
  watch: {
    projectId: {
      deep: true,
      handler() {
        this.project =
          (this.projects || []).filter((p) => p.id === this.projectId)[0] || {};
      },
    },
    project: {
      deep: true,
      handler() {
        // this.$store.dispatch("updateProject", this.project);
      },
    },
  },
  mounted() {
    /**
    if (typeof this.project.lists === "undefined") this.project.lists = [];
    if (typeof this.project.objects === "undefined") this.project.objects = [];
    for (let i = 0; i < this.project.lists.length; i += 1) {
      this.$set(this.project.lists[i], "newItemName", "");
    } **/
  },
  methods: {
    getColorByType(type) {
      return this.DESIGN_SCHEME.colors[type] || "#a9a9a8";
    },
    getNewCardButtonNameByType(type) {
      // return this.DESIGN_SCHEME.buttons.new_card[type] || "Lag et nytt element";
      return (
        this.$t("columns." + type).newCard || this.$t("buttons.addNewElement")
      );
    },
    listUpdate() {
      this.$emit("update");
    },
    test() {
      this.$emit("update");
    },
    check(...r) {
      const objId = r[0].dragged.id;
      const obj = this.project.data.objects.filter((e) => e.id === objId)[0];
      const toUpdated =
        `${obj.type}-lists` === r[0].to.id ||
        r[0].to.id === "teaching-design-lists";
      return toUpdated;
    },
    changeTitle(obj) {
      // eslint-disable-next-line no-param-reassign
      obj.title = obj.title.replace(/\r?\n|\r/g, "");
      // this.$store.dispatch("updateLists", this.lists);
    },
    createNewItem(list) {
      const title = list.newItemName.trim();
      list.newItemName = "";
      if (title.length > 0) {
        const id = uuidv4();
        const card = {
          id,
          type: list.type,
          title,
        };
        this.project.data.objects.push(card);
        list.cards.push(id);
        this.$emit("update");
        this.$nextTick(() => {
          const [el] = this.$refs[id];
          if (el?.$el?.scrollIntoView) {
            el.$el.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    editCard({ itemId }) {
      const cards = this.project.data.objects.filter((e) => e.id === itemId);
      const card = cards.length > 0 ? cards[0] : null;
      if (card) {
        this.$router.push({
          name: "Object",
          params: { item: itemId, id: this.project._id },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.board-container {
}
.board {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding-bottom: 8px;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
  display: block !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button {
  width: 12px;
  display: block;
}
:-webkit-scrollbar-track-piece {
  background: #00000026;
}

.list-wrapper:first-child {
  margin-left: 8px !important;
}
.list-wrapper {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  margin: 0 4px;
  vertical-align: top;
  white-space: nowrap;
  width: 272px;
}
.list {
  background-color: #ebecf0;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
  overflow: hidden;
  border-top-style: solid;
  border-top-width: 4px;
  border-top-left-radius: 4px 4px;
  border-top-right-radius: 4px 4px;
}
.list-header {
  flex: 0 0 auto;
  min-height: 28px;
  padding: 10px 8px;
  position: relative;
  -padding-right: 36px;
}
.list-header h2 {
  font-size: 14px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}
.list-header textarea {
  resize: none;
  -webkit-appearance: none;
  background-color: #fafbfc;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #dfe1e6;
  box-sizing: border-box;
  display: block;
  line-height: 28px;
  outline: none;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  font-size: 14px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  background: #0000;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 600;
  margin: -4px 0;
  padding: 4px 8px;
  overflow: hidden;
  overflow-wrap: break-word;
  height: 28px;
  cursor: pointer;
}

.list-header .title {
  background-color: #fafbfc;
  border: none;
  display: inline-block;
  line-height: 28px;
  outline: none;
  font-size: 12px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  background: #0000;
  font-weight: 600;
  padding: 4px 8px;
  overflow: hidden;
  overflow-wrap: break-word;
  height: 28px;
  cursor: pointer;
}

.list-cards {
  flex: 1 1 auto;
  margin: 0 4px;
  min-height: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px;
  z-index: 1;
}
.card-composer-container {
  display: flex;
  justify-content: space-between;
  max-height: 50px;
  min-height: 50px;
  border-top: 8px solid #ebecf0;
  padding-left: 4px;
  padding-right: 4px;
}
.tooltips-card {
  font-size: 12px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.ghost {
  background-color: #091e4240;
}
.ghost .list {
  opacity: 0;
}
.list-draggable {
  min-height: 28px;
  height: 100%;
}
.hidden {
  height: 1px !important;
  overflow: hidden;
}
.input-card {
  position: relative;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin: 4px 8px;
  word-break: break-all;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}

.board ::-webkit-scrollbar-button {
  width: 0px;
  display: none;
}

.dragging .list {
  transform: rotate(2.5deg);
}
</style>
