function compareRevisions(prev, next) {
  function getCardById(doc, id) {
    const card =
      (doc?.data?.objects || []).filter((e) => e.id === id)[0] || false;

    return card;
  }

  let action = false;
  let source = "user";
  let card = false;

  let next_title = next?.extras?.title || "";
  let prev_title = prev?.extras?.title || "";

  const log = {
    action,
    doc_id:
      typeof next?._id === "string"
        ? next?._id
        : next?._id?.id?.["$oid"] || next?._id.id || null,
    creatorId: next.creatorId,
    updatedById: next.updatedById,
    createdAt:
      typeof next?.createdAt === "string"
        ? next.createdAt
        : next._id.createdAt.$date || next._id.createdAt,
    updatedAt:
      typeof next?.updatedAt === "string"
        ? next.updatedAt
        : next._id.updatedAt.$date || next._id.updatedAt,
    prevUpdatedAt:
      typeof prev?.updatedAt === "string"
        ? prev.updatedAt
        : prev?._id?.updatedAt?.$date || prev?._id?.updatedAt || null,
    next_title,
    prev_title,
  };

  if (!action && prev && Object.keys(prev?.data || {}).length === 0) {
    action = "doc_filled_from_preset";
    source = "system";
  }

  if (!action && Object.keys(next?.data || {}).length === 0) {
    action = "doc_created";
  }

  if (!action && next_title !== prev_title) {
    action = "doc_renamed";
  }

  if (prev) {
    if (!action && Object.keys(prev.data).length === 0) {
      action = "doc_filled_from_preset";
      source = "system";
    }

    if (!action && prev.extras.title !== next.extras.title) {
      action = "doc_renamed";
    }

    if (!action) {
      const prev_cols = (prev.data?.lists || []).map((e) => e.id);
      const next_cols = (next.data?.lists || []).map((e) => e.id);
      if (prev_cols.toString() !== next_cols.toString()) {
        action = "doc_cols__moved";
      }
    }

    const prev_card_ids = (prev.data.objects || []).map((e) => e.id);
    const next_card_ids = (next.data.objects || []).map((e) => e.id);

    if (!action && prev_card_ids.length < next_card_ids.length) {
      action = "card_added";
      const card_id = next_card_ids.filter(
        (e) => prev_card_ids.indexOf(e) === -1
      )[0];
      card = getCardById(next, card_id);
    }
    if (!action && prev_card_ids.length > next_card_ids.length) {
      action = "card_deleted";
      const card_id = prev_card_ids.filter(
        (e) => next_card_ids.indexOf(e) === -1
      )[0];
      card = getCardById(prev, card_id);
    }

    if (
      !action &&
      prev_card_ids.filter((e) => next_card_ids.indexOf(e) !== -1).length ===
        prev_card_ids.length
    ) {
      const list_changes = [];
      next.data["lists"].forEach((next_list) => {
        const prev_list = prev.data["lists"].filter(
          (e) => e.id === next_list.id
        )[0];
        if (next_list.cards.toString() !== prev_list.cards.toString()) {
          list_changes.push([
            next_list.type,
            next_list.title,
            prev_list.cards,
            next_list.cards,
          ]);
        }
      });
      if (list_changes.length > 0) {
        if (list_changes.length === 1) {
          action = "card_dragged__in_col";
          log.card_dragged_from = list_changes[0][1];
          log.card_dragged_to = list_changes[0][1];
        }
        if (list_changes.length === 2) {
          action = "card_dragged__between_cols";
          if (list_changes[0][2].length < list_changes[0][3].length) {
            log.card_dragged_from = list_changes[1][1];
            log.card_dragged_to = list_changes[0][1];
          }
          if (list_changes[0][2].length > list_changes[0][3].length) {
            log.card_dragged_from = list_changes[0][1];
            log.card_dragged_to = list_changes[1][1];
          }
        }
      } else {
        next.data.objects.forEach((next_object) => {
          const prev_object = getCardById(prev, next_object.id);
          if (!action && next_object.title !== prev_object.title) {
            action = "card_updated__title";
            card = prev_object;
          }
          if (
            !action &&
            JSON.stringify(next_object.content || {}) !==
              JSON.stringify(prev_object.content || {})
          ) {
            action = "card_updated__content";
            card = prev_object;
          }
        });
      }
    }
  }

  if (!action) action = "empty";

  log.action = action;
  log.source = source;
  if (card?.id) {
    log.card_id = card?.id || null;
    log.card_title = card?.title || null;
    log.card_type = card?.type || null;
  }

  return log;
}

module.exports = compareRevisions;
