<template>
  <v-container>
    <v-data-table
      v-if="isAdmin"
      :headers="headers"
      :items="users"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>WHITELIST</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                small
              >
                New user
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          required
                          :rules="rules.nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.organization"
                          label="Organization"
                          required
                          :rules="rules.nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          :rules="rules.email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";

export default {
  name: "Admin",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: false,
    search: "",
    headers: [
      {
        text: "Users",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Organization", value: "organization" },
      // { text: "Tags", value: "tags" },
      { text: "Consent", value: "consent" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    editedIndex: -1,
    rules: {
      nameRules: [
        (v) => !!v || "Field is required",
        (v) => v.length >= 3 || "Field must be no less than 3 characters",
      ],
      email: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 64 || "Max 64 characters",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
    },
    editedItem: {
      name: "",
      organization: "",
      // tags: "",
      email: "",
    },
    defaultItem: {
      name: "",
      organization: "",
      // tags: "",
      email: "",
    },
  }),

  computed: {
    ...mapGetters(["isAdmin"]),
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  beforeMount() {
    if (!this.isAdmin) {
      this.$router.replace({ name: "Projects" });
    }
  },

  mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const consents = {};
      const tmp =
        (await this.$api.get("/users/consent/analytics/all"))?.data?.result ||
        [];
      if (Array.isArray(tmp)) {
        tmp.forEach((con) => {
          consents[con?.data.email] = con?.eventType;
          consents[con?.data.userId] = con?.eventType;
        });
      }

      this.$api
        .get("/useraccess")
        .then((res) => {
          this.users = res?.data?.result || [];
          this.users.forEach((user) => {
            const tmp = consents[user.id] || consents[user.email] || "unknown";
            user.consent = {
              revoked_consent: "rejected",
              unknown: "",
              registered_consent: "accepted",
            }[tmp];
          });
        })
        .catch(() => {
          this.users = [];
        });
    },

    editItem(item) {
      this.editedIndex = item?.id || -1;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item?.id || -1;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api
        .delete("/useraccess/" + this.editedIndex)
        .finally(() => this.initialize());
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.id) {
        this.$api
          .patch("/useraccess/" + this.editedItem.id, {
            name: this.editedItem.name,
            organization: this.editedItem.organization,
            tags: this.editedItem.tags || [],
            roles: this.editedItem.roles || [],
            email: this.editedItem.email,
            active: true,
          })
          .then(() => this.initialize());
      } else {
        this.$api
          .post("/useraccess", {
            name: this.editedItem.name,
            organization: this.editedItem.organization,
            tags: [],
            roles: [],
            email: this.editedItem.email,
            active: true,
          })
          .then(() => this.initialize());
      }
      this.close();
    },
  },
};
</script>

<style></style>
