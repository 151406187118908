<template>
  <v-container
    fluid
    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px"
  >
    <v-toolbar fixed>
      <div v-if="project.canEdit" class="d-print-none">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click.stop="switchView"
            >
              <v-icon small>
                {{ view === "cards" ? "mdi-file-document" : "" }}
                {{ view === "document" ? "mdi-trello" : "" }}
              </v-icon>
            </v-btn>
          </template>
          {{ view === "cards" ? $t("learningDesign.viewDocument") : "" }}
          {{ view === "document" ? $t("learningDesign.viewTable") : "" }}
        </v-tooltip>
      </div>
      <!--
      <v-tooltip
        bottom
        max-width="272"
        color="blue"
        content-class="custom-tooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" icon small>
            <v-icon small>{{ icons.mdiFilePdfBox }}</v-icon>
          </v-btn>
        </template>
        Eksporter til PDF
      </v-tooltip>
      -->
      <!--
      <div v-if="project.canEdit">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon small>
              <v-icon small>{{ icons.mdiPageNext }}</v-icon>
            </v-btn>
          </template>
          Neste revisjon
        </v-tooltip>
      </div>
      -->
      <div class="d-print-none">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click.stop="showComments = !showComments"
            >
              <v-icon small>
                {{ showComments ? "mdi-comment" : "mdi-comment-off" }}
              </v-icon>
            </v-btn>
          </template>
          {{
            showComments
              ? $t("learningDesign.hideComments")
              : $t("learningDesign.showComments")
          }}
        </v-tooltip>
      </div>
      <div class="d-print-none" v-if="view === 'document' && showComments">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click.stop="sortComments = !sortComments"
            >
              <v-icon small>
                {{
                  sortComments ? "mdi-sort-descending" : "mdi-sort-ascending"
                }}
              </v-icon>
            </v-btn>
          </template>
          {{
            sortComments
              ? $t("learningDesign.sortCommentsDesc")
              : $t("learningDesign.sortCommentsAsc")
          }}
        </v-tooltip>
      </div>
      <div class="d-print-none">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
          v-if="project.canEdit !== 88"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="dialogInviteEmail = true"
            >
              <v-icon small>mdi-account-supervisor</v-icon>
            </v-btn>
          </template>
          {{ $t("learningDesign.participants") }}
        </v-tooltip>
      </div>
      <div class="d-print-none" v-if="isCourseOwner">
        <v-tooltip
          bottom
          max-width="272"
          color="blue"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click.stop="downloadLogsFile"
            >
              <v-icon small>mdi-notebook</v-icon>
            </v-btn>
          </template>
          {{ $t("learningDesign.downloadLogsFile") }}
        </v-tooltip>
      </div>
      <v-divider class="mx-4 d-print-none" vertical></v-divider>
      <v-text-field
        dense
        v-model="projectTitle"
        :rules="titleRules"
        placeholder="Tittel på læreplanen"
        @input="changeTitle()"
        style="height: 50px !important"
        :readonly="!project.canEdit || view == 'document'"
        :append-icon="
          project.canEdit && view !== 'document' ? 'mdi-pencil' : ''
        "
      ></v-text-field>
    </v-toolbar>
    <div
      v-if="project._id"
      :class="{
        project: true,
        docview: view === 'document' || !project.canEdit,
      }"
    >
      <card-lists
        v-if="view === 'cards' && project.canEdit"
        :project="project"
        :key="'card-list' + historyId"
        v-on:update="update"
      />
      <document-view
        v-if="view === 'document' || !project.canEdit"
        :projectId="project.id"
        :key="'document-view' + historyId"
      />
    </div>
    <v-snackbar v-model="notification.show" :timeout="notification.timeout">
      {{ notification.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="
            notification.colors[notification.type] || notification.colors.normal
          "
          text
          v-bind="attrs"
          @click="notification.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogInviteEmail" width="500">
      <v-card>
        <v-card-title dense class="text-h5 grey lighten-2">
          {{ $t("learningDesign.participants") }}
        </v-card-title>

        <v-card-text>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                v-for="u in project.users"
                v-bind:key="u.id"
                :ripple="false"
              >
                <v-list-item-content>
                  {{ u.name || u.id }} [ {{ u.role }} ]
                </v-list-item-content>
                <v-list-item-icon
                  v-if="
                    (project.canEdit && u.role !== 'owner') || u.id === user.id
                  "
                >
                  <!--
                  <div
                    @click="
                      removeParticipant({
                        uid: u.id,
                        redirect: u.id === user.id,
                      })
                    "
                  >
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </div> -->
                  <v-menu offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark v-bind="attrs" v-on="on" icon small>
                        <v-icon color="red">mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>{{
                        $t("learningDesign.deleteParticipant")
                      }}</v-card-title>
                      <v-card-text class="pl-0 ml-0 mr-0 pr-0">
                        <v-list>
                          <v-list-item>
                            <v-list-item-title>
                              {{ $t("buttons.rejectDelete") }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            color="red"
                            @click="
                              removeParticipant({
                                uid: u.id,
                                redirect: u.id === user.id,
                              })
                            "
                          >
                            <v-list-item-title color="red"
                              ><span style="color: red !important">
                                {{ $t("buttons.acceptDelete") }}
                              </span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-card-text class="mb-0 pb-0" v-if="project.canEdit">
          <v-list dense>
            <v-list-item-group>
              <v-list-item inactive :ripple="false">
                <v-text-field
                  dense
                  :label="$t('learningDesign.newParticipantEmail')"
                  v-model="emailForInvite"
                  append-icon="mdi-email"
                  v-if="project.participants.length === 0"
                />
                <v-combobox
                  dense
                  v-model="emailForInvite"
                  :label="$t('learningDesign.newParticipantOrEmail')"
                  :items="project.participants"
                  append-icon="mdi-email"
                  item-text="label"
                  v-if="project.participants.length > 0"
                ></v-combobox>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions
          class="mt-0 pt-0 pb-4 justify-center"
          v-if="project.canEdit"
        >
          <v-btn
            small
            color="primary"
            text
            @click="sendInvitation('supervisor')"
            :disabled="addSupervisorDisabled"
          >
            {{ $t("learningDesign.inviteSupervisor") }}
          </v-btn>
          <v-btn
            small
            color="primary"
            text
            @click="sendInvitation('teacher')"
            :disabled="addTeacherDisabled"
          >
            {{ $t("learningDesign.inviteTeacher") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */

import { mapGetters, mapState } from "vuex";
import CardLists from "./Project/CardLists.vue";
import DocumentView from "./Project/DocumentView.vue";
import { mdiHumanMaleBoard, mdiFilePdfBox, mdiPageNext } from "@mdi/js";
import { throws } from "assert";

import compareRevisions from "@/libs/compare-revisions/";

export default {
  name: "Project",
  components: { CardLists, DocumentView },

  data: () => ({
    icons: {
      mdiHumanMaleBoard,
      mdiFilePdfBox,
      mdiPageNext,
    },
    showComments: true,
    sortComments: false,
    view: "cards",
    projectTitle: "",
    titleError: false,
    titleRules: [
      (value) => !!value || "Obligatorisk felt",
      (value) => (value && value.length >= 3) || "Min. tre tegn",
    ],
    updateTimeout: false,
    dialogInviteEmail: false,
    emailForInvite: "",
    notification: {
      show: false,
      text: "Notification...",
      timeout: 3000,
      type: "normal",
      colors: {
        normal: "blue",
        error: "red",
      },
    },
    addTeacherDisabled: true,
    addSupervisorDisabled: true,
  }),

  watch: {
    emailForInvite(val) {
      this.addTeacherDisabled = true;
      this.addSupervisorDisabled = true;
      if (typeof val === "string" && val.length > 0) {
        this.addTeacherDisabled = false;
        this.addSupervisorDisabled = false;
      } else {
        if (val.role === "teacher") this.addTeacherDisabled = false;
        if (val.role === "supervisor") this.addSupervisorDisabled = false;
      }
    },
    showComments: {
      handler(val) {
        this.$store.state.showComments = val;
      },
    },
    sortComments: {
      handler(val) {
        this.$store.state.sortComments = val;
      },
    },
    project: {
      handler(val) {
        if (!val._id) {
          this.$router.replace("/");
          return;
        }
      },
    },
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["projects", "project", "user"]),
    ...mapState(["historyId"]),
    isCourseOwner() {
      return this.user.roles.indexOf("course_owner") !== -1;
    },
  },
  async mounted() {
    const pid = this.$route?.params?.id;

    await this.$store.dispatch("getProject", pid);

    if (!this.project?._id) {
      this.$router.replace("/");
      return;
    }
    this.projectTitle = this.project?.extras?.title || "";

    this.sortComments = this.$store.state.sortComments;
    this.showComments = this.$store.state.showComments;
  },
  beforeDestroy() {
    this.$store.state.data.project = {};
  },
  methods: {
    switchView() {
      if (this.view === "cards") {
        this.view = "document";
      } else {
        this.view = "cards";
      }
    },
    changeTitle() {
      clearTimeout(this.updateTimeout);
      if (this.projectTitle.trim().length < 3) {
        return;
      }
      this.project.extras.title = this.projectTitle;
      this.updateTimeout = setTimeout(() => {
        this.update();
      }, 1000);
    },
    update() {
      this.$store.dispatch("updateProject", this.project);
    },
    sendInvitation(role) {
      this.$api
        .post("/invites", {
          email: (
            this.emailForInvite?.email ||
            this.emailForInvite ||
            ""
          ).toLowerCase(),
          role: role,
          subject: this.project._id,
        })
        .then((res) => {
          this.notification.type = "normal";
          this.notification.show = true;
          this.notification.text = "The invitation has been created";
        })
        .catch((error) => {
          this.notification.type = "error";
          this.notification.show = true;
          this.notification.text =
            error?.response?.data?.error || "Something went wrong";
        })
        .finally(() => {
          this.emailForInvite = "";
          this.dialogInviteEmail = false;
          this.$store.dispatch("updateInvites");
        });
    },
    async removeParticipant({ uid, redirect }) {
      await this.$api.delete("/roles/" + this.project._id + "/" + uid);
      if (redirect) {
        this.$store.dispatch("getProjects");
        this.$router.replace({ name: "Home" });
      } else {
        this.$store.dispatch("getProject", this.$route?.params?.id);
      }
    },
    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async loadComments() {
      let comments = [];

      const res = await this.$api.get(
        "/comments/containers/" + this.project._id
      );
      res.data.result.comments.forEach((comment) => {
        const cid = comment.containerSection;
        comments.push({
          action: cid ? "card_commented" : "doc_commented",
          doc_id: this.project._id,
          comment_id: comment._id,
          creatorId: comment.createdBy,
          updatedById: comment.createdBy,
          createdAt: comment.createdAt,
          updatedAt: comment.createdAt,
          text: comment.text,
          card_id: cid,
        });
      });
      return comments;
    },
    async downloadLogsFile() {
      const comments = await this.loadComments();

      this.$api
        .get(`/containers/${this.project._id}/revisions?complete=true`)
        .then((res) => {
          if (res.data && res?.data?.error === false) {
            let logs = comments.length ? comments : [];
            const data = res?.data?.result || [];

            const revisions = JSON.parse(JSON.stringify(data));

            for (let i = 0; i < data.length; i++) {
              const next = data[i];
              const prev = data[i - 1] || false;
              logs.push(compareRevisions(prev, next, i));
            }

            logs = logs.sort((a, b) => {
              if (a.updatedAt > b.updatedAt) return 1;
              if (a.updatedAt < b.updatedAt) return -1;
              return 0;
            });
            this.download(
              `logs_${this.project._id}.json`,
              JSON.stringify(
                {
                  logs,
                  revisions,
                },
                null,
                4
              )
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}
.list {
  background-color: #ebecf0;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  white-space: normal;
  overflow: hidden;
}
.list-header {
  flex: 0 0 auto;
  min-height: 20px;
  padding: 10px 8px;
  position: relative;
  padding-right: 36px;
}
.list-header h2 {
  font-size: 14px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}
.list-header textarea {
  resize: none;
  -webkit-appearance: none;
  background-color: #fafbfc;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #dfe1e6;
  box-sizing: border-box;
  display: block;
  line-height: 28px;
  outline: none;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  font-size: 14px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  background: #0000;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 600;
  margin: -4px 0;
  padding: 4px 8px;
  overflow: hidden;
  overflow-wrap: break-word;
  height: 28px;
  cursor: pointer;
}
.project {
  margin-bottom: 8px;
  padding-bottom: 8px;
  position: absolute;
  top: 82px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-x: auto !important;
  overflow-y: hidden;
}
@media print {
  .project {
    margin-bottom: 8px;
    padding-bottom: 8px;
    position: static;
  }
}
</style>

<style>
.custom-tooltip {
  opacity: 1 !important;
  padding: 8px;
}
.custom-tooltip li {
  padding-bottom: 8px;
}
</style>
