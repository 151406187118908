<template>
  <div>
    <div ref="editor"></div>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */

import Quill from "quill";
import "@/libs/quill-mention/quill.mention";
// import '@/libs/quill-mention/quill.mention.css';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "CardEditor",
  props: {
    card: Object,
    projectId: String,
    project: Object,
  },
  prev: {},
  data: () => ({
    show: true,
    itemId: "",
    listId: "",
    editor: null,
    saveDisabled: false,
    editorContainer: null,
    group: {},
    groupName: "",
  }),
  beforeDestroy() {
    if (this.editor?.enable) {
      this.editor.enable(false);
    }
    this.editor = null;
    if (this?.$refs?.editor) this.$refs.editor.innerHTML = "";

    window.removeEventListener("mention-clicked", this.mentionClicked, false);
  },
  mounted() {
    this.$nextTick(this.placeEditor);
  },
  methods: {
    mentionClicked(event) {
      const data = event.value;
      // ToDo: add group type to mention
      if (data.denotationChar === "#") {
        this.$router.replace({
          name: "Object",
          params: { id: this.projectId, item: data.id },
        });
      }
    },
    placeEditor() {
      const self = this;

      if (!self.$refs?.editor) return;
      const toolbar = [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        ["link"],
        // ['image', 'video', 'code-block'],
      ];

      if (self.$refs?.editor) self.$refs.editor.innerHTML = "";

      self.editorContainer = document.createElement("div");
      self.$refs.editor.appendChild(self.editorContainer);

      // eslint-disable-next-line no-undef
      self.editor = new Quill(self.editorContainer, {
        theme: "snow",
        modules: {
          toolbar,
          // https://github.com/afry/quill-mention
          mention: {
            // allowedChars: /^[0-9.A-Za-z\sÅÄÖåäö]*$/,
            allowedChars: /^[-\p{L}|\p{N}|\s]*$/gu,
            mentionDenotationChars: ["@", "#"],
            positioningStrategy: "fixed",
            minChars: 0,
            dataAttributes: [
              "id",
              "value",
              "type",
              "typeName",
              "denotationChar",
              "target",
              "disabled",
            ],
            // showDenotationChar: false,
            renderItem: (data) => {
              if (data.disabled) {
                return `<div class="disabled">${data.value}</div>`;
              }
              return data.value;
            },
            source(searchTerm, renderList, mentionChar) {
              let matches = [];
              // ~values[i].value.toLowerCase().indexOf(term.toLowerCase())

              const term = searchTerm.trim().toLowerCase();

              let groups = self.project.data.lists.map((l) => ({
                id: l.id,
                value: l.title,
                type: l.type,
                cards: l.cards,
              }));

              if (mentionChar === "@") {
                if (term.length === 0) {
                  matches = groups;
                } else {
                  matches = groups.filter(
                    (g) => g.value.toLowerCase().indexOf(term) !== -1
                  );
                }
              }

              if (mentionChar === "#") {
                if (term.length > 0) {
                  const objects = self.project.data.objects
                    .filter((e) => e.title.toLowerCase().indexOf(term) !== -1)
                    .map((l) => ({
                      id: l.id,
                      value: l.title,
                      type: l.type,
                    }));
                  const ids = objects.map((e) => e.id);
                  groups = groups.filter(
                    (g) =>
                      g.cards.filter((id) => ids.indexOf(id) !== -1).length > 0
                  );

                  groups.forEach((group) => {
                    // eslint-disable-next-line no-param-reassign
                    group.disabled = true;
                    matches.push(group);
                    group.cards.forEach((cid) => {
                      const cards = objects.filter(
                        (o) => o.id === cid && ids.indexOf(cid) !== -1
                      );
                      const card = cards.length > 0 ? cards[0] : null;
                      if (card) {
                        card.typeName = group.value;
                        matches.push(card);
                      }
                    });
                  });
                }
              }

              renderList(matches, term);
              // ToDo: объекты выбирать из групп
            },
          },
        },
      });
      self.editor.setContents(self.card.content || {});
      self.editor.on("text-change", function () {
        self.card.content = self.editor.getContents();
      });

      window.addEventListener("mention-clicked", self.mentionClicked, false);
    },
  },
};
</script>
<style scoped></style>
<style>
.ql-mention-list-item {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}
.ql-mention-list-item .disabled {
  font-weight: 600;
}
</style>
