<template>
  <div
    :id="id"
    :class="{
      'list-card': true,
      'dragg-item': item.fixed !== true,
      'card-fixed': item.fixed === true,
    }"
    :style="{
      'min-height': type === 'teaching-design' ? '50px' : '24px',
      'border-left-color': getColorByType(item.type),
    }"
  >
    <div :class="{ 'card-content': true }">
      <v-btn
        v-if="type === 'teaching-design'"
        icon
        style="float: right"
        @click.stop="swapItem"
        ><v-icon small>mdi-swap-horizontal</v-icon></v-btn
      >
      {{ item.title }}
      <p v-for="x in item.lines" v-bind:key="x">&nbsp;</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Card",
  props: {
    id: String,
    project: Object,
    type: String,
  },
  data: () => ({
    item: {},
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["DESIGN_SCHEME"]),
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.item = this.project.data.objects.filter((e) => e.id === this.id)[0];
  },
  methods: {
    getColorByType(type) {
      return this.DESIGN_SCHEME.colors[type] || "#a9a9a8";
    },
    swapItem() {
      let from = this.project.data.lists.filter((l) => l.type === this.type)[0];
      let to = this.project.data.lists.filter(
        (l) => l.type === this.item.type
      )[0];
      from.cards = from.cards.filter((c) => c !== this.id);
      to.cards.unshift(this.id);
      this.$emit("update");
    },
  },
};
</script>
<style scoped>
.list-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 0 #091e4240;
  cursor: pointer;
  display: block;
  margin-bottom: 8px;
  max-width: 300px;
  min-height: 24px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  padding: 4px 8px;
  font-size: 14px !important;
  color: #172b4d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  border-left-width: 4px;
  border-left-style: solid;
  border-top-left-radius: 4px 4px;
  border-bottom-left-radius: 4px 4px;
}
.ghost .card-content {
  opacity: 0;
}
.card-fixed {
  background-color: #091e4240;
  font-weight: 600;
}
</style>
