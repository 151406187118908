<template>
  <v-menu :close-on-content-click="false" offset-x v-model="menu">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip
        bottom
        max-width="272"
        color="blue"
        content-class="custom-tooltip"
        v-model="tooltip"
      >
        <template v-slot:activator="{ on: mouse, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="mouse"
            v-on:click="onMenu.click"
            icon
            small
          >
            <v-icon small>{{ icons.mdiCommentMultiple }}</v-icon>
          </v-btn>
        </template>
        {{ $t("learningDesign.leaveComment") }}
      </v-tooltip>
    </template>
    <v-card shaped tile>
      <v-list>
        <v-list-item>
          <!--
          <v-text-field
            autofocus
            ref="input"
            dense
            v-model="comment"
          ></v-text-field>
          -->
          <v-textarea
            autofocus
            v-model="comment"
            class="textarea-comment text-body-2"
          ></v-textarea>
          <v-list-item-action>
            <v-btn
              icon
              color="green"
              @click="saveComment"
              :disabled="comment.length < 4"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiCommentMultiple } from "@mdi/js";

export default {
  name: "CommentWithButton",
  props: {
    projectId: String,
    sectionId: String,
  },
  data: () => ({
    comment: "",
    tooltip: false,
    menu: false,
    icons: {
      mdiCommentMultiple,
    },
  }),
  watch: {
    tooltip() {
      if (this.menu) {
        this.$nextTick(() => {
          this.tooltip = false;
        });
      }
    },
    menu(val) {
      this.tooltip = false;
      if (!val) {
        this.comment = "";
      }
    },
  },
  mounted() {},
  methods: {
    saveComment() {
      if (this.comment.length > 3) {
        this.$api
          .post("/comments/containers/" + this.projectId, {
            text: this.comment,
            containerSectionId: this.sectionId,
          })
          .then(() => {
            this.$emit("refresh");
          })
          .catch(() => {})
          .finally(() => {
            this.comment = "";
            this.menu = false;
          });
      }
    },
  },
};
</script>
<style>
.textarea-comment {
  min-width: 420px;
}
.v-textarea textarea {
  line-height: 1.2rem !important;
}
</style>
