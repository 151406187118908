<template>
  <div class="viewer">
    <div
      class="editorHolder"
      v-if="card.content && holderId"
      :id="holderId"
      v-html="html"
    />
  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import AttachesTool from "@editorjs/attaches";

export default {
  name: "DocumentViewItem",
  props: {
    card: Object,
  },
  data: () => ({
    holderId: null,
    editor: null,
    editorContainer: null,
    html: "",
  }),
  computed: {},
  watch: {},
  beforeDestroy() {
    if (this.editor?.enable) {
      this.editor.enable(false);
    }
    this.editor = null;
    if (this?.$refs?.editor) this.$refs.editor.innerHTML = "";
  },
  mounted() {
    this.$store.state.aid++;
    this.holderId = "aid" + this.$store.state.aid;
    if (this.card.content) this.$nextTick(this.placeEditor);
  },
  methods: {
    placeEditor() {
      const self = this;
      self.editor = new EditorJS({
        holder: self.holderId,
        data: self.card.content,
        logLevel: "ERROR",
        autofocus: true,
        /**
         * This Tool will be used as default
         */
        initialBlock: "paragraph",
        readOnly: true,
        tools: {
          header: {
            class: Header,
            shortcut: "CMD+SHIFT+H",
          },
          list: {
            class: List,
            inlineToolbar: ["link"],
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: ".",
            },
          },
          image: {
            class: ImageTool,
          },
          attaches: {
            class: AttachesTool,
          },
        },
      });
    },
  },
};
</script>
<style>
.editorHolder .codex-editor__redactor {
  padding-bottom: 0px !important;
}
.image-tool__image-picture {
  max-height: 350px;
}
.image-tool__caption {
  border: none !important;
  box-shadow: none !important;
}
.ce-block__content {
  max-width: 100% !important;
}
.ce-toolbar__content {
  max-width: 100% !important;
}
</style>
