<template>
  <v-dialog
    v-model="show"
    scrollable
    :transition="false"
    width="90%"
    max-width="900px"
    persistent
  >
    <v-card>
      <v-toolbar class="pa-2">
        <v-row>
          <v-col cols="10">
            <v-text-field
              :placeholder="$t('card.titlePlaceHolder')"
              v-model="card.title"
              :label="groupName"
              :rules="titleRules"
              hide-details="auto"
              append-icon="mdi-pencil"
              dense
            />
          </v-col>
          <v-col cols="2" align="right" class="pr-0">
            <!--
            <v-btn x-small color="orange" fab @click="closeEditor">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          -->
            <v-btn small dark color="red" @click="closeEditor">
              {{ $t("card.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-row>
          <v-col cols="1">&nbsp;</v-col>
          <v-col :cols="comments.length === 0 ? 10 : 6">
            <card-editor-quill
              v-if="editorName === 'quill'"
              :card="card"
              :projectId="projectId"
              :project="project"
            />
            <card-editor-editor-j-s
              v-if="editorName === 'editorjs'"
              :card="card"
              :projectId="projectId"
              :project="project"
            />
          </v-col>
          <v-col v-if="comments.length > 0" cols="4">
            <template v-if="comments.length > 0">
              <v-list dense class="pa-0 ma-0">
                <v-list-item
                  v-for="comment in comments"
                  v-bind:key="comment.id"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >{{ new Date(comment.createdAt).toLocaleString() }}
                      |
                      {{ users[comment.createdBy] }}</v-list-item-subtitle
                    >
                    <v-list-item-title>{{ comment.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template></v-col
          >
          <v-col cols="1">&nbsp;</v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!--
        <v-btn
          icon
          small
          color="primary"
          @click="saveCard"
          :disabled="saveDisabled"
        >
          <v-icon dense>mdi-content-save</v-icon>
        </v-btn> -->
        <v-btn
          small
          color="primary"
          @click="saveCard"
          :disabled="saveDisabled"
          >{{ $t("card.save") }}</v-btn
        >
        <v-spacer />
        <v-card
          v-if="$t('columns.' + card.type).tooltips"
          tile
          max-width="350px"
          class="text-caption pa-2"
        >
          <ul>
            <li
              v-for="(tip, i) in $t('columns.' + card.type).tooltips"
              :key="i"
            >
              {{ tip }}
            </li>
          </ul>
        </v-card>
        <v-spacer />
        <!--
        <v-btn small icon color="red" @click="deleteCard">
          <v-icon dense>mdi-delete</v-icon>
        </v-btn>
      -->
        <v-btn small color="red" @click="deleteCard" dark>{{
          $t("card.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";
import CardEditorQuill from "./CardEditorQuill.vue";
import CardEditorEditorJS from "./CardEditorEditorJS.vue";

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export default {
  name: "CardEditor",
  prev: {},
  components: {
    CardEditorQuill,
    CardEditorEditorJS,
  },
  data: () => ({
    show: true,
    editorName: "undefined",
    projectId: false,
    itemId: "",
    listId: "",
    card: {},
    saveDisabled: false,
    group: {},
    groupName: "",
    titleRules: [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 1) || "Min 1 characters",
    ],
    TITLE_PLACEHOLDER: "Skriv inn navnet ditt...",
    comments: [],
    users: {},
    showComments: false,
  }),
  computed: {
    ...mapGetters(["projects", "project"]),
  },
  watch: {
    // eslint-disable-next-line func-names
    "card.title": function (val) {
      if (val.trim().length < 1) {
        this.saveDisabled = true;
      } else {
        this.saveDisabled = false;
      }
    },
    show(val) {
      if (val === false) {
        const projectId = this.$route.params.id;
        this.$router.replace({ name: "Project", id: projectId });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      try {
        this.projectId = this.project._id;
        this.itemId = this.$route.params.item;

        const cards = clone(
          (this.project?.data.objects || []).filter(
            (e) => e.id === this.itemId && !e.archived
          )
        );

        this.card = cards.length > 0 ? cards[0] : {};
        const groups = (this.project?.data.lists || []).filter(
          (e) => e.type === this.card.type // e.cards.indexOf(this?.card?.id) !== -1
        );
        this.group = groups.length > 0 ? groups[0] : null;
        if (!this?.card?.id || !this?.group?.id)
          this.$router.push({ name: "Projects" });

        this.groupName = this.$t("columns." + this.group.type).title;
        this.listId = this.group.id;

        this.editorName = this.project?.data?.config?.editor || "quill";
      } catch (error) {
        this.$router.replace({ name: "Projects" });
      }
      this.loadComments();
    });
  },
  methods: {
    closeEditor() {
      this.show = false;
      // const projectId = this.$route.params.id;
      // this.$router.replace({ name: "Project", id: projectId });
    },
    async deleteCard() {
      const self = this;
      self.project.data.lists.forEach((list) => {
        list.cards = list.cards.filter((id) => id !== this.itemId);
      });
      self.project.data.objects = self.project.data.objects.filter(
        (e) => e.id !== this.itemId
      );
      await this.$store.dispatch("updateProject", this.project);
      this.$nextTick(function () {
        self.$router.replace({ name: "Project", id: self.projectId });
      });
    },
    saveCard() {
      const self = this;
      const mapper = self.project.data.objects.filter(
        (e) => e.id === self.card.id
      )[0];
      Object.assign(mapper, self.card);
      this.$store.dispatch("updateProject", this.project);
      this.closeEditor();
    },
    loadComments() {
      const self = this;
      const comments = [];
      const users = {};
      this.$api.get("/comments/containers/" + this.projectId).then((res) => {
        (res.data.result.users || []).forEach((u) => {
          users[u.id] = u.name;
        });
        res.data.result.comments.forEach((comment) => {
          const cid = comment.containerSection;
          if (cid === self.itemId) {
            comments.unshift({
              id: comment._id,
              createdAt: comment.createdAt,
              text: comment.text,
              createdBy: comment.createdBy,
            });
          }
        });
        this.comments = comments;
        this.users = users;
        this.$forceUpdate();
      });
    },
  },
};
</script>
<style scoped>
.input-card {
  position: relative;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin: 4px 8px;
  word-break: break-all;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}
.v-list-item__title {
  white-space: normal;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb:vertical {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
